.rtable {
    width: 100%;
    border-spacing: 0px;
}

.rtable thead {
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-weight: bold;
    background-color: #094D00;
    color: white;
    text-align: center;
    border: 2px solid #FFFFFF;
    border-bottom: 1px solid #d3d9df;
}
.rtable td {
    padding: 8px;
}
.rtable thead tr td {
    border: 1px solid #d3d9df;
}

.rtable tbody tr td {
    border: 0px;
    border-bottom: 1px solid #d3d9df;
    text-align: center;
}

.rtable tbody tr:nth-child(2n) {
        background-color: #f3f9ff;
}
.rtable tbody tr:nth-child(2n-1) {
        background-color: white;
}

.rtable tbody tr:hover {
    background-color: lightgreen;
    cursor: pointer;
}

@-webkit-keyframes kfhighlightchangeodd {
        0% { background-color: white; }
        10% { background-color: red; }
        100% { background-color: white; }
}

@-webkit-keyframes kfhighlightchangeodd1 {
        0% { background-color: white; }
        10% { background-color: red; }
        100% { background-color: white; }
}

@-webkit-keyframes kfhighlightchangeeven {
        0% { background-color: #f3f9ff; }
        10% { background-color: red; }
        100% { background-color: #f3f9ff; }
}

@-webkit-keyframes kfhighlightchangeeven1 {
        0% { background-color: #f3f9ff; }
        10% { background-color: red; }
        100% { background-color: #f3f9ff; }
}

tbody tr:nth-child(2n-1) .highlightchange0 {
    -webkit-animation: kfhighlightchangeodd 10s;
}

tbody tr:nth-child(2n) .highlightchange0 {
    -webkit-animation: kfhighlightchangeeven 10s;
}

tbody tr:nth-child(2n-1) .highlightchange1 {
    -webkit-animation: kfhighlightchangeodd1 10s;
}

tbody tr:nth-child(2n) .highlightchange1 {
    -webkit-animation: kfhighlightchangeeven1 10s;
}

.classes-onekp-container {
    display: inline-flex;
	width: 190px;
}
